<template>
  <router-view />
</template>

<script setup>
import { ref } from 'vue';
import { message } from 'ant-design-vue';
const selectedKeys = ref(['1']);
const info = () => {
  message.info('敬请期待');
};
</script>
<script>

export default {
  components: {

  },
  methods: {
    goHref(url) {
      this.$router.push(url)
    },
    goshop() {
      location.href = 'https://shop.jimimedia.cn/'
    }
  }
  ,
  data() {
    return {

    }
  },
  created() {
    // this.$http.post('getUserInfo').then(res => {

    // }).catch(error => {

    // })
  }
}
</script>
<style>
.juzhong{
  margin: 0 auto;
}
.kuan90{
  width: 90%;
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "钉钉进步体 Regular";
  font-weight: 400;
  src: url("assets/DingTalk-JinBuTi.woff2") format("woff2");
  font-display: swap;
}

.dingTalkFont {
  font-family: "钉钉进步体 Regular";
  letter-spacing: 1px;
}

* {
  /* font-family: "钉钉进步体 Regular"!important; */
}

body {
  /* background: #f5f5f5; */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}


</style>