import { createRouter, createWebHistory } from 'vue-router';
import { showFailToast } from 'vant';

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/firstInfo',
    name: 'FirstInfo',
    component: () => import('../views/FirstInfo.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/portal',
    redirect:'/portal/home',
    name: 'portal',
    component: () => import('../views/Portal.vue'),
    children: [
      {
        path: 'home',
        name: 'portalIndex',
        component: () => import('../views/portal/PortalView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'my',
        name: 'portalMy',
        component: () => import('../views/portal/MyView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'post',
        name: 'postView',
        component: () => import('../views/portal/PostView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'bmb',
        name: 'bmb',
        component: () => import('../views/portal/BmbView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'admin',
        name: 'admin',
        component: () => import('../views/portal/AdminView.vue'),
        meta: { requiresAuth: true },
      },
    ]
  },
  // {
  //   path: '/ticket/:ticketid',
  //   name: 'ticket',
  //   component: () => import('../views/ticket/NewTicketView.vue')
  // },

  // {
  //   path: '/:pathMatch(.*)',
  //   name: 'NotFoundView',
  //   component: () => import('../views/NotFoundView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  const userInfo = localStorage.getItem('userResult');
  const localtoken = localStorage.getItem('token') || null;
  if (to.meta.requiresAuth && (!localtoken || !userInfo)) {
    showFailToast('请登录');
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;