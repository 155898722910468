import axios from 'axios';
import { useRouter } from 'vue-router';
import { showLoadingToast, closeToast, showFailToast } from 'vant';

// 创建 axios 实例
const http = axios.create({
  baseURL: '/api',
  timeout: 5000
});

// 请求拦截器
http.interceptors.request.use(
  config => {
    const localtoken = localStorage.getItem('token') || null;
    if (localtoken) {
      config.headers['Authorization'] = 'Bearer ' + localtoken;
    }
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    config.headers.timestamp = Math.floor(new Date().getTime() / 1000);

    // 显示加载中 toast
    if (config.loading === true) {
      showLoadingToast({ message: '加载中...', duration: 0 });
    }

    return config;
  },
  error => {
    closeToast();
    showFailToast('请求错误');
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  response => {
    closeToast();
    return response;
  },
  error => {
    closeToast();
    showFailToast('响应错误');
    return Promise.reject(error);
  }
);

// 封装 GET 请求
function get(url, data = {}, loading = false) {
  return new Promise((resolve, reject) => {
    http.get(url, { params: data, loading: loading }).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    ).catch(error => {
      reject(error);
    });
  });
}

// 封装 POST 请求
function post(url, data = {}, loading = false) {
  return new Promise((resolve, reject) => {
    http.post(url, data, { loading: loading }).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    ).catch(error => {
      reject(error);
    });
  });
}

// 封装 DELETE 请求
function del(url, data = {}, loading = false) {
  return new Promise((resolve, reject) => {
    http.delete(url, { data: data, loading: loading }).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    ).catch(error => {
      reject(error);
    });
  });
}

// 封装 PUT 请求
function put(url, data = {}, loading = false) {
  return new Promise((resolve, reject) => {
    http.put(url, data, { loading: loading }).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    ).catch(error => {
      reject(error);
    });
  });
}

export {
  http,
  get,
  post,
  del,
  put
};
