import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'vant/lib/index.css';
import vant from 'vant'


import TDesignMobile from 'tdesign-mobile-vue'
import 'tdesign-mobile-vue/es/style/index.css'

import TDesign from 'tdesign-vue-next'
import 'tdesign-vue-next/es/style/index.css'

import 'ant-design-vue/dist/reset.css'
import Antd from 'ant-design-vue'

import { get, post } from './JS/ajax'


import ArcoVue from '@arco-design/web-vue'
// import '@arco-themes/vue-0000/css/arco.css'
import '@arco-themes/vue-digitforce/css/arco.css'

const app = createApp(App)

app.config.globalProperties.$http = {
    get,
    post
}

import { Toast } from 'tdesign-mobile-vue';

app.config.globalProperties.$Toast = Toast
app.use(vant)
app.use(Antd)
// app.use(TDesign)
app.use(TDesignMobile)
app.use(ArcoVue)

app.use(router).mount('#app')
